import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DefaultLocale } from '@app/+authenticated/shared/locale/locale.helper';
import { RouteModalComponent } from '@app/+modals/route-modal.component';
import { handleSubmitError } from '@app/forms/form.helper';
import { ValidationService } from '@app/forms/validation.service';
import { VatValidator } from '@app/shared/async-validators/vat-validator/vat.validator';
import { VatResponseStatus } from '@app/shared/billing/billing.model';
import { SbFormFieldComponent } from '@app/shared/sb-lib/forms/sb-form-field.component';
import { SbSpinnerComponent } from '@app/shared/sb-spinner.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccountService } from '@reducers/account/account.service';
import { AuthService } from '@reducers/auth/auth.service';
import { ButtonComponent, IconComponent, SelectComponent, SelectItem } from '@sb/ui';
import { BillingService } from '@shared/billing/billing.service';
import { ContentStateComponent } from '@shared/content-state/content-state.component';
import { SharedLaddaModule } from '@shared/shared-ladda.module';
import { of, Subscription } from 'rxjs';

export interface VatChanges {
  status: VatResponseStatus;
  vatNumber: string;
}

@Component({
  selector: 'billing-details-form',
  templateUrl: './billing-details-form.component.html',
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    SharedLaddaModule,
    ContentStateComponent,
    IconComponent,
    RouteModalComponent,
    CommonModule,
    SbSpinnerComponent,
    SbFormFieldComponent,
    ButtonComponent,
    SelectComponent,
  ],
  styles: [':host { display: block; }'],
})
export class BillingDetailsFormComponent implements OnInit, OnDestroy {
  @Input()
  public backButtonEnabled: boolean;
  @Input()
  public saveLabel: string;
  @Input()
  public hideButtonBar = false;
  @Input()
  public noPadding = false;
  @Output()
  public backPressed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public saved: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public countryCodeChange = new EventEmitter<string>();
  @Output()
  public vatStatusChange = new EventEmitter<VatChanges>();

  public isLoading: boolean;
  public isDataLoading = true;

  public error: string;
  public countries: SelectItem[] = [];

  public vatStatus: VatResponseStatus = undefined;
  public vatResponseStatus = VatResponseStatus;

  @Input()
  public hideSubmitButton = false;

  private dataSubs = new Subscription();

  public billingDetailsForm = new FormGroup({
    invoice_company: new FormControl('', [Validators.required]),
    invoice_email: new FormControl('', [Validators.required, ValidationService.emailValidator]),
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    street_address: new FormControl('', [Validators.required]),
    zipcode: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    vat: new FormControl(''),
  });

  public constructor(
    private accountService: AccountService,
    private authService: AuthService,
    private translateService: TranslateService,
    private billingService: BillingService,
  ) {}

  public ngOnInit(): void {
    // Add async validator on init to have country control available
    this.billingDetailsForm
      .get('vat')
      .addAsyncValidators([VatValidator.createValidator(this.billingService, this.billingDetailsForm.get('country'))]);

    this.dataSubs.add(
      this.accountService.fetchInvoiceSettings().subscribe({
        next: (res) => {
          const account = res.Account;
          this.billingDetailsForm.patchValue(account);
          this.isDataLoading = false;
        },
      }),
    );

    this.dataSubs.add(
      this.authService.fetchCountries(this.translateService.currentLang ?? DefaultLocale).subscribe({
        next: (countries) => {
          this.countries = Object.entries(countries).map(([value, label]) => ({ text: label, value }));
        },
      }),
    );

    this.dataSubs.add(
      this.billingDetailsForm.get('country').valueChanges.subscribe((countryCode) => {
        this.billingDetailsForm.get('vat').updateValueAndValidity();
        this.countryCodeChange.emit(countryCode);
      }),
    );

    this.dataSubs.add(
      this.billingDetailsForm.get('vat').statusChanges.subscribe((status) => {
        if (status !== 'PENDING') {
          // Wait until the async validator has finished
          this.vatStatusChange.emit({
            status: this.billingDetailsForm.get('vat')['vatStatus'],
            vatNumber: this.billingDetailsForm.get('vat').value,
          });
        }
      }),
    );
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  public submit() {
    if (!this.billingDetailsForm.valid || this.isLoading) {
      return of(false);
    }

    this.isLoading = true;

    const form = this.billingDetailsForm.value;
    const request = this.accountService.patchInvoiceSettings(form);

    this.dataSubs.add(
      request.subscribe({
        next: () => {
          this.billingService.billingUpdate$.next(null);
          this.isLoading = false;
          this.saved.emit();
        },
        error: (err) => this.handleError(err),
      }),
    );

    return request;
  }

  public handleError(error: HttpErrorResponse) {
    this.isLoading = false;
    handleSubmitError(error, this.billingDetailsForm);
  }
}
