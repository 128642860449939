import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { VatResponse, VatResponseStatus } from '@shared/billing/billing.model';
import { BillingService } from '@shared/billing/billing.service';
import { Observable, of, switchMap, timer } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { catchError, map } from 'rxjs/operators';

export class VatValidator {
  private static lastVatNumber: string;

  public static createValidator(billingService: BillingService, countryCtrl: AbstractControl): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> =>
      // Emulate debounceTime and distinctUntilChanged
      // with timer and filter because they don't work with async validators
      timer(1000).pipe(
        switchMap(() => {
          const vatNumber = control.value;

          if (vatNumber === this.lastVatNumber) {
            // Ladda directive triggers a new validation when the button is clicked
            // but the value hasn't changed, so this will prevent the request
            return of(control['vatStatus'] === VatResponseStatus.INVALID ? { invalidVat: true } : null);
          }

          this.lastVatNumber = vatNumber;

          if (!vatNumber || vatNumber.length < 5) {
            control['vatStatus'] = VatResponseStatus.UNDETERMINED;
            return of(null);
          }

          // Remove country code (if present) and update field value
          // This will trigger a value change event and the validator will be called again
          const country = countryCtrl.value;
          if (vatNumber.startsWith(country)) {
            control.patchValue(vatNumber.slice(country.length));
            control['vatStatus'] = VatResponseStatus.UNDETERMINED;
            return of(null);
          }

          control['validating'] = true;

          const payload = { country, vat_number: vatNumber };
          const cbInstance = billingService.getChargeBeeInstance();

          return fromPromise(cbInstance.load('functions')).pipe(
            switchMap(() => fromPromise(cbInstance.vat.validateVat(payload))),
            map((vatResponse: VatResponse) => {
              control['validating'] = false;
              control['vatStatus'] = vatResponse.status;

              return vatResponse.status === VatResponseStatus.INVALID ? { invalidVat: true } : null;
            }),
            catchError(() => {
              control['validating'] = false;
              control['vatStatus'] = VatResponseStatus.INVALID;
              return of({ invalidVat: true });
            }),
          );
        }),
      );
  }
}
