<form class="form" [formGroup]="billingDetailsForm" (ngSubmit)="submit()" novalidate id="invoice-settings-form">
  <content-state [isLoading]="isDataLoading">
    <div class="flex flex-col gap-2" [ngClass]="{ 'p-4': !noPadding }">
      <sb-form-field
        [fullWidth]="true"
        [noPadding]="true"
        [errorWhen]="['touched']"
        [control]="billingDetailsForm.get('invoice_company')"
        [label]="'Company name' | translate"
      >
        <input
          type="text"
          id="invoice_company"
          class="form__element--input"
          name="invoice_company"
          [formControl]="billingDetailsForm.get('invoice_company')"
        />
      </sb-form-field>

      <sb-form-field
        [fullWidth]="true"
        [noPadding]="true"
        [errorWhen]="['touched']"
        [control]="billingDetailsForm.get('invoice_email')"
        [label]="'Billing email' | translate"
      >
        <input
          type="text"
          id="invoice_email"
          class="form__element--input"
          name="invoice_email"
          [formControl]="billingDetailsForm.get('invoice_email')"
        />
      </sb-form-field>

      <div class="flex gap-4">
        <sb-form-field
          class="flex-1"
          [fullWidth]="true"
          [noPadding]="true"
          [errorWhen]="['touched']"
          [control]="billingDetailsForm.get('first_name')"
          [label]="'First name' | translate"
        >
          <input
            type="text"
            id="first_name"
            class="form__element--input"
            name="first_name"
            [formControl]="billingDetailsForm.get('first_name')"
          />
        </sb-form-field>

        <sb-form-field
          class="flex-1"
          [fullWidth]="true"
          [noPadding]="true"
          [errorWhen]="['touched']"
          [control]="billingDetailsForm.get('last_name')"
          [label]="'Last name' | translate"
        >
          <input
            type="text"
            id="last_name"
            class="form__element--input"
            name="last_name"
            [formControl]="billingDetailsForm.get('last_name')"
          />
        </sb-form-field>
      </div>

      <sb-form-field
        [fullWidth]="true"
        [noPadding]="true"
        [errorWhen]="['touched']"
        [control]="billingDetailsForm.get('street_address')"
        [label]="'Address' | translate"
      >
        <input
          type="text"
          id="street_address"
          class="form__element--input"
          name="street_address"
          [formControl]="billingDetailsForm.get('street_address')"
        />
      </sb-form-field>

      <sb-form-field
        [fullWidth]="true"
        [noPadding]="true"
        [errorWhen]="['touched']"
        [control]="billingDetailsForm.get('zipcode')"
        [label]="'Zipcode' | translate"
      >
        <input
          type="text"
          id="zipcode"
          class="form__element--input"
          name="zipcode"
          [formControl]="billingDetailsForm.get('zipcode')"
        />
      </sb-form-field>

      <sb-form-field
        [fullWidth]="true"
        [noPadding]="true"
        [errorWhen]="['touched']"
        [control]="billingDetailsForm.get('city')"
        [label]="'City' | translate"
      >
        <input
          type="text"
          id="city"
          class="form__element--input"
          name="city"
          [formControl]="billingDetailsForm.get('city')"
        />
      </sb-form-field>

      <sb-form-field
        [fullWidth]="true"
        [noPadding]="true"
        [errorWhen]="['touched']"
        [control]="billingDetailsForm.get('country')"
        [label]="'Country' | translate"
      >
        <sb-select
          [formControl]="billingDetailsForm.get('country')"
          [items]="countries"
          [searchable]="true"
        ></sb-select>
      </sb-form-field>

      <sb-form-field
        [fullWidth]="true"
        [noPadding]="true"
        [control]="billingDetailsForm.get('vat')"
        [label]="'VAT number' | translate"
        [overrideError]="billingDetailsForm.get('vat').errors?.invalidVat"
        [overrideErrorMessage]="'Invalid VAT number' | translate"
      >
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon text-grey-500">{{ billingDetailsForm.value.country }}</span>
          <input
            type="text"
            id="vat"
            class="form__element--input"
            name="vat"
            [formControl]="billingDetailsForm.get('vat')"
          />
          <sb-spinner
            *ngIf="billingDetailsForm.get('vat')?.['validating']; else noValidating"
            class="extra-small absolute right-2 top-1.5"
          ></sb-spinner>
          <ng-template #noValidating>
            <sb-icon
              *ngIf="billingDetailsForm.get('vat')?.['vatStatus'] === vatResponseStatus.VALID"
              class="absolute right-2 top-2.5 text-success-600"
              size="lg"
              name="check"
            ></sb-icon>
          </ng-template>
        </div>
      </sb-form-field>
    </div>

    <div class="flex justify-end gap-2 px-4 pb-1 pt-7" *ngIf="!hideButtonBar">
      <button sb-button [color]="'grey'" *ngIf="backButtonEnabled" [disabled]="isLoading" (click)="backPressed.emit()">
        {{ 'Back' | translate }}
      </button>
      <button
        sb-button
        [color]="'primary'"
        type="submit"
        [sbLadda]="isLoading"
        [disabled]="billingDetailsForm.status === 'PENDING'"
      >
        {{ saveLabel ?? 'Save' | translate }}
      </button>
    </div>
  </content-state>
</form>
